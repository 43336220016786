<template>
  <v-card flat tile class="charcoal">
    <v-sheet
      color="transparent"
      class="pa-2 mx-0 mt-2"
      :style="charcoalTileMenu"
    >
      <v-card-title class=" paper--text text-h4 pl-0 pa-2">
        STRENGTH

        <v-spacer />
        <v-switch
          color="progressActive "
          dark
          title="Fine Scale?"
          v-model="finescale"
          @click="setScale"
        >
          <template v-slot:label>
            <span class="silver--text">Fine Scale?</span>
          </template>
        </v-switch>
      </v-card-title>
      <v-card-actions
        v-if="what.label != null && what.label != 'Overall'"
        class="pa-0"
      >
        <span :class="textSizeSmall + '  silver--text'">
          {{ what.label }}
        </span>
      </v-card-actions>
      <v-card-actions
        v-if="max > 0"
        :class="textSizeXSmall + ' paper--text px-0 py-0'"
      >
        <span class="pinkAccent--text mr-1"> Min: {{ min }} </span>
        <span class="blueAccent--text mr-1"> Max: {{ max }} </span>
        <span class="orange--text"> Avg: {{ avg }} </span>
        <v-spacer />
      </v-card-actions>
      <v-card-title v-else :class="textSizeXSmall + ' silver--text py-0'">
        No Data Available.
      </v-card-title>
      <v-select
        solo
        height="60"
        dense
        color="nicegreen"
        item-color="black"
        background-color="charcoal lighten-2"
        dark
        v-model="strengthby"
        return-object
        prepend-inner-icon="mdi-calendar"
        :items="by"
        label="By"
        item-text="text"
        item-value="value"
        @change="loadItems"
        :menu-props="{
          closeOnClick: true,
          maxHeight: 280,
          transition: 'fab-transition'
        }"
      />
      <MyLineChart
        v-if="loaded"
        :chartdata="chartdata"
        :options="options"
        :key="switchnum"
      />
    </v-sheet>
  </v-card>
</template>

<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const MyLineChart = () =>
  import(/* webpackPrefetch: true */ '@/components/charts/MyLineChart.vue')
export default {
  components: { MyLineChart },
  mixins: [util],
  data: () => ({
    chartdata: {},
    options: {},
    items: [],
    weights: [],
    values: [],
    labels: [],
    bwratio: [],
    loaded: false,
    unitDisplay: '',
    min: 0,
    max: 200,
    avg: 0,
    interval: 10,
    offsset: 5,
    switchnum: 0,
    stepsize: 5,
    finescale: false,
    strengthby: {},
    byMetric: {
      text: 'Absolute Weight Metric',
      value: 'absolute_metric'
    },
    byImperial: {
      text: 'Absolute Weight Imperial',
      value: 'absolute_imperial'
    },
    groupby: { text: 'All Time', value: 'alltime' },
    by: [
      { text: 'Absolute Weight Imperial', value: 'absolute_imperial' },
      { text: 'Absolute Weight Metric', value: 'absolute_metric' },
      { text: 'Relative to Bodyweight', value: 'relative' }
    ]
  }),
  props: {
    mode: {
      type: String,
      default: 'overall'
    },
    thousands: {
      type: Boolean,
      default: true
    },
    what: {
      type: Object
    },
    selection: {
      type: Object
    },
    exerciseid: {
      type: String
    },
    metric: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    metric: function() {
      if (this.metric) this.strengthby = this.byMetric
      else this.strengthby = this.byImperial
      this.loadItems()
    }
  },
  mounted() {
    if (this.selection) this.groupby = this.selection
    if (this.metric) this.strengthby = this.byMetric
    else this.strengthby = this.byImperial
    this.loadItems()
  },
  validations: {},
  computed: {
    ...appConfig,
    ...builder
  },
  methods: {
    setScale() {
      if (this.finescale) this.stepsize = 1
      else this.stepsize = 5
      this.loadItems()
      this.switchnum++
    },
    loadItems() {
      {
        this.$emit('group_by', this.groupby)
        let liftingBase = '/stats/strength/history/' + this.exerciseid
        let url = this.baseURL + liftingBase

        return axios.get(url, {}).then(response => {
          if (response.status == 200) {
            this.items = response.data.data

            this.labels = this.items.map(function(obj) {
              return obj.text
            })

            if (this.strengthby.value == 'absolute_imperial') {
              this.unitDisplay = 'lbs'
              this.offset = 10
              this.values = this.items.map(function(obj) {
                return parseFloat(obj.imperial)
              })
            } else if (this.strengthby.value == 'absolute_metric') {
              this.unitDisplay = 'kg'
              this.offset = 5
              this.values = this.items.map(function(obj) {
                return parseFloat(obj.metric)
              })
            } else {
              this.offset = 0.1
              this.unitDisplay = 'BW'
              this.values = this.items.map(function(obj) {
                return parseFloat(obj.bwratio)
              })
            }
            this.min = this.arrayMin(this.values)
            this.max = this.arrayMax(this.values)
            this.avg = this.arrayAvg(this.values)

            let unit = this.unitDisplay

            this.chartdata = {
              labels: this.labels,
              datasets: [
                {
                  label:
                    '1 RM: ' + (this.what.label ? ' ' + this.what.label : ''),
                  backgroundColor: '#fafafa',
                  borderColor: '#8BC34A',
                  borderWidth: 1,
                  data: this.values,
                  radius: 4,
                  pointHoverRadius: 15,
                  pointHoverBorderWidth: 10,
                  pointHoverBorderColor: '#212b31',
                  fill: false,
                  pointBackgroundColor: '#53a653',
                  lineTension: 0.1,
                  showLines: true
                },
                {
                  label: 'Avg.',
                  data: Array.apply(null, new Array(this.values.length)).map(
                    Number.prototype.valueOf,
                    this.avg
                  ),
                  fill: false,
                  radius: 0,
                  borderColor: 'orange',
                  borderWidth: 1,
                  border: '1'
                },
                {
                  label: 'Max',
                  data: Array.apply(null, new Array(this.values.length)).map(
                    Number.prototype.valueOf,
                    this.max
                  ),
                  fill: false,
                  radius: 0,
                  borderColor: '#b0e8ff',
                  borderWidth: 1,
                  border: '1'
                },
                {
                  label: 'Min',
                  data: Array.apply(null, new Array(this.values.length)).map(
                    Number.prototype.valueOf,
                    this.min
                  ),
                  fill: false,
                  radius: 0,
                  borderColor: '#ff63c5',
                  borderWidth: 1,
                  border: '1'
                }
              ]
            }
            this.options = {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                labels: {
                  fontColor: '#9e9e9e',
                  boxWidth: 2,
                  usePointStyle: false
                }
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      zeroLineColor: '#9e9e9e',
                      color: '#444'
                    },
                    ticks: {
                      stepSize: this.stepsize,
                      fontColor: '#efefef',
                      tickColor: '#9e9e9e'
                    }
                  }
                ],
                yAxes: [
                  {
                    suggestedMin: Math.max(this.min - this.offsset, 0),
                    suggestedMax: this.max + this.offsset,
                    gridLines: {
                      zeroLineColor: '#9e9e9e',
                      color: '#444'
                    },
                    ticks: {
                      stepSize: this.stepsize,
                      fontColor: '#efefef',
                      tickColor: '#9e9e9e',
                      callback: function(label) {
                        return label + ' ' + unit
                      }
                    }
                  }
                ]
              }
            }
            this.loaded = true
            this.$emit('get_max', this.max)
            this.switchnum++
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
      }
    }
  }
}
</script>
